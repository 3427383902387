import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/hr/layout"
import SEO from "../../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img from "gatsby-image"
import Container from "react-bootstrap/Container"

export const query = graphql`
    query($slug: String!) {
        service: directusServicesTranslation(language: {eq: "hr"}, service: {slug: {eq: $slug}}) {
            seo_description
            seo_title
            title
            pre_headline
            content
            image {
                localFile {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    publicURL
                    extension
                }
            }
        }
    }
`

const IndexPage = ({ data, pageContext }) => {
  return (
    <Layout>
      <SEO title={ data.service.seo_title } description={ data.service.seo_description } lang={"hr"} url={`/services/${pageContext.slug}`} />
      <Container fluid>
        <Row className={"site-content align-items-center"}>
          <Col md={5} className={"offset-md-1"}>
            <h4>{ data.service.pre_headline }</h4>
            <h1>{ data.service.title }</h1>
            <div dangerouslySetInnerHTML={{ __html:  data.service.content }}/>
          </Col>
          <Col xs={12} sm={12} md={6} className={"p-0"}>
            <Img fluid={data.service.image.localFile.childImageSharp.fluid} style={{ width: `100%` }} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
