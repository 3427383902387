import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"
import Logo from "../logo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Header = () => {
  const data = useStaticQuery(graphql`
      query {
          services: allDirectusServicesTranslation(filter: {language: {eq: "hr"}}) {
              nodes {
                  link_name
                  service {
                      slug
                  }
              }
          }
          company: directusCompany {
              linkedin
              twitter
              facebook
          }
      }
  `)

  return (
    <div className={"fixed-top"}>
      <Row className={"pre-nav align-items-center justify-content-end d-md-none d-sm-none d-flex"}>
        <Col xs={"auto"}><Link to="/">EN</Link></Col>
        <Col xs={"auto"}><Link to="/it">IT</Link></Col>
      </Row>
      <Row className={"pre-nav align-items-center justify-content-end"}>
        <Col xs={"auto"}><a href={data.company.linkedin}><i className="fab fa-linkedin"/></a></Col>
        <Col xs={"auto"}><a href={data.company.twitter}><i className="fab fa-twitter"/></a></Col>
        <Col xs={"auto"}><a href={data.company.facebook}><i className="fab fa-facebook-square"/></a></Col>
        <Col xs={"auto"}><Link to="/hr/cookie-policy">Cookie Policy</Link></Col>
        <Col xs={"auto"}><Link to="/hr/privacy-policy">Privacy Policy</Link></Col>
      </Row>
      <Navbar expand="lg">
        <Navbar.Brand href="/hr" style={{ width: `240px` }}>
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/hr">Naslovna</Nav.Link>
            <Nav.Link as={Link} to="/hr/about">O nama</Nav.Link>
            { data.services.nodes.length ? (
              <NavDropdown title="Usluge">
                { data.services.nodes.map(service => (
                  <NavDropdown.Item as={Link} to={`/hr/services/${service.service.slug}`} key={service.service.slug}>{ service.link_name }</NavDropdown.Item>
                ))}
              </NavDropdown>
            ) : (<></>)}
            <Nav.Link as={Link} to="/hr/contacts">Kontakti</Nav.Link>
          </Nav>
          <Nav className={"d-md-flex d-sm-flex d-none"}>
            <Nav.Link as={Link} to={`/`}>EN</Nav.Link>
            <Nav.Link as={Link} to={`/it`}>IT</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default Header
